<template>
  <div class="card">
    <div class="card-body">
      <h4>Recettes les plus plébiscitées (10)</h4>
      <p>
        Recettes classées par le nombre de fois où elles apparaissent dans une
        sélection hebdomadaire
      </p>
      <b-form-checkbox v-model="displayAll" name="check-button" switch>
        Afficher les {{ metrics.length }} résultats
      </b-form-checkbox>
      <div class="mt-2">
        <b-table striped hover :items="recipes" :fields="fields"></b-table>
      </div>
    </div>
    <!-- <pre>{{ metrics }}</pre> -->
  </div>
</template>
<script>
/* eslint-disable */
import BarChart from '@/components/BarChart'
import { BTable, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'MetricsSubscriptions',
  components: {
    BTable,
    BFormCheckbox,
    BarChart,
  },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayAll: false,
      fields: [
        {
          key: 'label',
          label: 'Recettes',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Nb. Plats',
          sortable: true,
        },
        {
          key: 'selection_count',
          label: 'Nb. Sélection',
          sortable: true,
        },
        {
          key: 'average',
          label: 'Moyenne',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    recipes() {
      let results = []
      if (this.metrics.length > 0) {
        if (this.displayAll === true) {
          results = this.metrics
        } else {
          results = this.metrics.slice(0, 10)
        }
      }
      return results
    },
  },
  methods: {},
}
</script>
