<template>
  <div>
    <div v-if="$store.getters['user/profile'].role == 'ADMINISTRATOR'">
      <metrics-subscriptions
        v-if="metrics.SUBSCRIPTIONS_PERIODS"
        :metrics="metrics.SUBSCRIPTIONS_PERIODS"
      />
      <metrics-recipes
        v-if="metrics.RECIPES_USERS_SELECTIONS"
        :metrics="metrics.RECIPES_USERS_SELECTIONS"
      />
    </div>
    <b-card v-if="selection.id" title="Sélection en cours">
      <b-card-text>
        <p>
          Semaine {{ selection.week_of_year }} du
          {{ $dayjs(selection.start_at).format('DD.MM.YYYY') }}
          au
          {{ $dayjs(selection.end_at).format('DD.MM.YYYY') }}
        </p>
        <vue-slick-carousel
          v-if="selection.id"
          v-bind="VueSlickCarouselSettings"
          class="m-2 p-2"
          style="background-color: #f6f6f6"
        >
          <div
            v-for="recipe in selection.recipes"
            :key="recipe.id"
            style="background-color: #f6f6f6"
          >
            <div>
              <b-img :src="recipe.thumb_list_url" fluid thumbnail />
            </div>
            <div class="pr-1 pl-1 text-center">
              <h4>
                {{ recipe.label }}
              </h4>
            </div>
          </div>
        </vue-slick-carousel>
      </b-card-text>
    </b-card>
    <!-- <debug>{{ metrics.RECIPES_USERS_SELECTIONS }}</debug> -->
    <!-- <debug>{{ metrics }}</debug> -->
    <!-- <debug>{{ selection }}</debug> -->
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import MetricsSubscriptions from '@/components/MetricsSubscriptions.vue'
import MetricsRecipes from '@/components/MetricsRecipes.vue'

import { BCard, BCardText, BImg } from 'bootstrap-vue'

export default {
  components: {
    MetricsSubscriptions,
    MetricsRecipes,
    BCard,
    BCardText,
    VueSlickCarousel,
    BImg,
  },
  data() {
    return {
      loading: true,
      selection: {},
      metrics: {},
      statistics: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Commandes',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Clients',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Recettes',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Ventes',
          customClass: '',
        },
      ],
      VueSlickCarouselSettings: {
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.$store.getters['user/profile'].role === 'ADMINISTRATOR') {
        const respm = await this.$http.get('/metrics/dashboard/')
        this.metrics = respm.data
      }

      const resp = await this.$http.get('/metrics/current-recipes-selection/', {
        headers: {
          'X-Fields': '*,recipes{id,label,thumb_list_url}',
        },
      })
      this.selection = resp.data
    },
  },
}
</script>

<style>
.slick-slider {
  position: relative;
}
.slick-next::before {
  color: black;
}

.slick-prev::before {
  color: black;
}
</style>
