<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-lg-4">
          <h4>Abonnements</h4>
          <small> Évolution des abonnements par période </small>
        </div>
        <div class="col-lg-8 border">
          <div class="text-center pt-1">
            <b-form-radio-group
              id="btn-radios-1"
              v-model="period"
              :options="chartOptions"
              name="radios-btn-default"
              size="sm"
              buttons
              class="d-inline-block"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="card-stats">
            <div class="card-body">
              <div class="d-flex">
                <div class="text-center text-info">
                  <font-awesome-icon icon="cubes" class="fa-4x" />
                </div>
                <div class="flex-fill text-muted text-right">
                  <p v-if="period == 'DAYS'">Aujourd'hui</p>
                  <p v-if="period == 'WEEKS'">Cette semaine</p>
                  <p v-if="period == 'MONTHS'">Ce mois</p>
                  <p class="h2 text-right">{{ computedThisPeriod }}</p>
                </div>
              </div>
            </div>
            <hr />
            <div class="text-right">
              <b>{{ computedLastPeriod }}</b>
              <span v-if="period == 'DAYS'" class="card-category">
                abonnements hier
              </span>
              <span v-if="period == 'WEEKS'" class="card-category">
                abonnements la semaine dernière
              </span>
              <span v-if="period == 'MONTHS'" class="card-category">
                abonnements le mois dernier
              </span>

              <br />
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <bar-chart
            :labels="computedLabels"
            :height="140"
            :color="color"
            :extra-options="options"
            :datasets="computedDatasets"
          />
        </div>
      </div>
      <div v-if="displayData" class="m-5">
        <h4 class="card-title">Données</h4>
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <td>Le</td>
              <td class="text-right">Annulation</td>
              <td class="text-right">Création</td>
              <td class="text-right">Total</td>
              <td class="text-right">Montants</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, k) in metrics.periods[`CREATED-${period}`].data"
              :key="`subscriptions-${k}`"
            >
              <td>{{ data[0] }}</td>
              <td class="text-right">
                {{ metrics.periods[`CANCELED-${period}`].data[k][1] }}
              </td>

              <td class="text-right">{{ data[1] }}</td>
              <td class="text-right">
                {{ data[1] - metrics.periods[`CANCELED-${period}`].data[k][1] }}
              </td>
              <td class="text-right">
                {{
                  (data[2] - metrics.periods[`CANCELED-${period}`].data[k][2])
                    | currency
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <pre>{{ computedLabels }}</pre> -->
    <!-- <pre>{{ computedDatasets }}</pre> -->
    <!-- <pre>{{ metrics }}</pre> -->
  </div>
</template>
<script>
/* eslint-disable */
import BarChart from '@/components/BarChart'
import { BFormGroup, BFormRadioGroup } from 'bootstrap-vue'

export default {
  name: 'MetricsSubscriptions',
  components: {
    BFormGroup,
    BFormRadioGroup,
    BarChart,
  },
  props: {
    metrics: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: [
        { text: 'Mois', value: 'MONTHS' },
        { text: 'Semaines', value: 'WEEKS' },
        { text: 'Jours', value: 'DAYS' },
      ],
      displayData: false,
      labels: [],
      status: 'CREATED',
      period: 'WEEKS',
      datasets: [
        {
          label: 'Abonnements annulés',
          borderColor: '#cf2c3b',
          fill: true,
          backgroundColor: '#cf2c3b',
          hoverBorderColor: '#cf2c3b',
          borderWidth: 5,
          data: [],
        },
        {
          label: 'Abonnements créés',
          borderColor: '#51bcda',
          fill: true,
          backgroundColor: '#51bcda',
          hoverBorderColor: '#51bcda',
          borderWidth: 5,
          data: [],
        },
      ],
      color: null,
      options: null,
    }
  },
  computed: {
    computedThisPeriod() {
      let result = 0
      if (this.metrics && this.metrics.periods) {
        const data = JSON.parse(
          JSON.stringify(
            this.metrics.periods[`${this.status}-${this.period}`].data
          )
        )
        data.reverse()
        /* eslint-disable */
        result = data.pop()[1]
      }
      return result
    },
    computedLastPeriod() {
      let result = 0
      if (this.metrics && this.metrics.periods) {
        const data = JSON.parse(
          JSON.stringify(
            this.metrics.periods[`${this.status}-${this.period}`].data
          )
        )
        data.reverse()
        result = data.slice(-2).shift()[1]
      }
      return result
    },
    computedthisMonth() {
      let result = 0
      if (this.metrics && this.metrics.periods) {
        const data = JSON.parse(
          JSON.stringify(this.metrics.periods[`${this.status}-MONTHS`].data)
        )
        data.reverse()
        result = data.pop()[1]
      }
      return result
    },
    computedLastMonth() {
      let result = 0
      if (this.metrics && this.metrics.periods) {
        const data = JSON.parse(
          JSON.stringify(this.metrics.periods[`${this.status}-MONTHS`].data)
        )
        data.reverse()
        result = data.slice(-2).shift()[1]
      }
      return result
    },
    computedthisWeek() {
      let result = 0
      if (this.metrics && this.metrics.periods) {
        const data = JSON.parse(
          JSON.stringify(this.metrics.periods[`${this.status}-WEEKS`].data)
        )
        data.reverse()
        result = data.pop()[1]
      }
      return result
    },
    computedLastWeek() {
      let result = 0
      if (this.metrics && this.metrics.periods) {
        const data = JSON.parse(
          JSON.stringify(this.metrics.periods[`${this.status}-WEEKS`].data)
        )
        data.reverse()
        result = data.slice(-2).shift()[1]
      }
      return result
    },
    computedLabels() {
      const data = []
      if (this.metrics && this.metrics.periods) {
        this.metrics.periods[`${this.status}-${this.period}`].data.forEach(
          (d) => {
            if (this.period === 'DAYS') {
              data.push(this.$dayjs(d[0]).format('ddd DD', 'fr'))
            } else if (this.period === `WEEKS`) {
              data.push(this.$dayjs(d[0]).format('ddd DD', 'fr'))
            } else {
              data.push(this.$dayjs(d[0]).format('MMM', 'fr'))
            }
          }
        )
        data.reverse()
      }
      return data
    },
    computedTitle() {
      let title = ''
      if (this.period === `${this.status}-DAYS`) {
        title = 'Jours'
      } else if (this.period === `${this.status}-WEEKS`) {
        title = 'Semaines'
      } else {
        title = 'Mois'
      }
      return title
    },
    computedDatasets() {
      const datasets = JSON.parse(JSON.stringify(this.datasets))
      datasets[0].data = []
      datasets[1].data = []
      if (this.metrics && this.metrics.periods) {
        this.metrics.periods[`CREATED-${this.period}`].data.forEach((d) => {
          datasets[1].data.push(d[1])
        })
        datasets[1].data.reverse()
        this.metrics.periods[`CANCELED-${this.period}`].data.forEach((d) => {
          datasets[0].data.push(d[1])
        })
        datasets[0].data.reverse()
      }
      return datasets
    },
  },
  methods: {},
}
</script>
